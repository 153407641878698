.App {
  text-align: center;
}

.swiper-slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.image-slider-items {
  height: auto;
  width: 80%;
}

.support-guy {
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-swiper-card {
  height: 300px !important;
  width: 250px !important;
  margin-right: 2rem !important;
}

.rastchin {
  text-align: center;
  border-radius: 8px;
  border: 1px solid #cecece;
}

.contract-rastchin {
  flex-direction: row-reverse;
  text-align: right;
}

.about-fixit {
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: bold;
  font-size: 20px;
  padding: 2rem;
  border-radius: 8px;
  border-top: 2px solid #cecece;
  border-bottom: 2px solid #cecece;
}

.image-brand-fixit {
  height: 85%;
  width: 15%;
  border-radius: 50px;
  box-shadow: 0px 0px 16px 2px white;
}

.brand-div {
  height: 100px;
  width: 100%;
  display: flex;
  /* overflow: hidden; */
  justify-content: center;
  align-items: center;
}
.navmenu {
  transition: all 2s linear !important;
}