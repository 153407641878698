.services-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 50%;
    text-align: center;
    transition: all 1s linear;

}

.image-service-box {
    height: 100%;
    width: 100%;
}

.icon-service {
    height: auto;
    width: 70%;
}

.flip-card:hover {
    transform: scale(1.2) !important;
}

/* @media only screen and (min-width : 800px) { */
    .sevices-row:hover .flip-card:not(:hover){
        filter: blur(2px);
        transform: scale(.8) !important;
    }
/* } */


